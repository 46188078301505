<template>
  <div class="hot-news-container">
    <page-nav></page-nav>
    <jump></jump>
    <div class="detail">
      <div class="position">您当前位置：教育资源网 > 热点资讯</div>
      <div class="content">
        <div class="ql-editor" v-html="infoContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import PageNav from "@/components/PageNav/index.vue";
import Jump from "@/components/Jump/index.vue";
import "quill/dist/quill.snow.css";
export default {
  components: {
    PageNav,
    Jump,
  },
  data() {
    return {
      infoContent: "",
    };
  },
  methods: {
    getHotNews() {
      this.$api.getHotNews(this.$route.query.bannerId).then((res) => {
        if (res.code === 200) {
          this.infoContent = res.data.infoContent;
        } else {
          // console.log(res);
          this.$message.error(res.msg);
        }
      });
    },
  },
  mounted() {
    // console.log(this.$route.query.bannerId);
  },
  created() {
    this.getHotNews();
  },
};
</script>

<style scoped lang="less">
.hot-news-container {
  width: 100%;
  .detail {
    .position {
      padding: 24px 120px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
    }
    .content {
      width: 100%;
      padding: 12px 278px 50px;
      box-sizing: border-box;
      overflow: hidden;
      .ql-editor {
        width: 100%;
        ::v-deep img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
